import React from "react";
import { Route, Routes } from "react-router";
import Home from "../Pages/Home";
import { Box } from "@chakra-ui/react";
import Report from "../Pages/Report";
import PrivateRoute from "./PrivateRoute";
import Purchase from "../Pages/Purchase";
import RawMeterialSales from "../Pages/RawMeterialSales";
import Payment from "../Pages/Payment";
import Products from "../Pages/Products";
import OrderReport from "../Pages/OrderReport";
import ClientFeedback from "../Pages/ClientFeedback";
import RawReport from "../Pages/RawReport";
import HowToVideo from "../Pages/HowToVideo";
import PaymentQR from "../Pages/PaymentQR";
import BcTypePrivateRoute from "./BcTypePrivateRoute";

const AllRoutes = () => {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/report"
          element={
            <PrivateRoute>
              <Report />
            </PrivateRoute>
          }
        />
        <Route
          path="/genqr/:id"
          element={
            <BcTypePrivateRoute>
              <PaymentQR />
            </BcTypePrivateRoute>
          }
        />
        <Route
          path="/how-to-videos"
          element={
            <PrivateRoute>
              <HowToVideo />
            </PrivateRoute>
          }
        />
        <Route
          path="/rawreport"
          element={
            <PrivateRoute>
              <RawReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchase"
          element={
            <PrivateRoute>
              <Purchase />
            </PrivateRoute>
          }
        />
        <Route
          path="/sales"
          element={
            <PrivateRoute>
              <RawMeterialSales />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          }
        />
        <Route
          path="/order/:id"
          element={
            <PrivateRoute>
              <OrderReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/feedback"
          element={
            <PrivateRoute>
              <ClientFeedback />
            </PrivateRoute>
          }
        />
      </Routes>
    </Box>
  );
};

export default AllRoutes;
