import React, { useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Image,
  Flex,
  Center,
  useToast,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import logo from "../Resources/logo.png";
import ProfileButton from "../Component/ProfileButton";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/AuthReducer/Action";

const PaymentQR = () => {
  const toast = useToast();
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const [upiID, setUpiID] = useState("");
  const upiAmount=useRef(null);
  const [qrCodeImage, setQrCodeImage] = useState("");
  const resetqr = () => {
    setQrCodeImage("");
    // setQrCodeData("");
  };
  const handleGenerateQRCode = () => {
    if (upiID && upiAmount.current.value) {
      const data = `upi://pay?pa=${upiID}&pn=Ruma Enterprise&am=${upiAmount.current.value}&cu=INR&tn=Payment for Order No ${id}`;
      QRCode.toDataURL(data)
        .then((url) => {
          setQrCodeImage(url);
          //   setQrCodeData(data);
        })
        .catch((err) => {
        });
    } else {
      toast({
        title: "Please fill in all fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDownloadQRCode = () => {
    if (qrCodeImage) {
      const link = document.createElement("a");
      link.href = qrCodeImage;
      link.download = `qr_${id}.jpg`;
      link.click();
    }
  };
  return (
    <Box>
      <Flex justifyContent={"space-between"} mb={"10px"} p={"10px"}>
        <Link to={"/"}>
          <Image h={"40px"} src={logo} alt="logo" />
        </Link>

        <ProfileButton handleLogin={handleLogout} />
      </Flex>
      <Container centerContent py={window.innerWidth <= 1024 ? 4 : 20}>
        <Box
          w="full"
          bg="white"
          p={6}
          rounded="lg"
          shadow="lg"
          textAlign="center"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
        >
          <Text
            fontSize={window.innerWidth <= 1024 ? "lg" : "2xl"}
            fontWeight="bold"
            mb={6}
          >
            Payment QR Code Generate an Download
          </Text>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            gap={"20px"}
            flexDirection={["column", "column", "row", "row"]}
          >
            <Box>
              <FormControl mb={4}>
                <Select
                  id="upiID"
                  onChange={(e) => {
                    setUpiID(e.target.value);
                    resetqr();
                  }}
                  size="lg"
                  borderColor="#ddd"
                >
                  <option hidden>Select UPI ID</option>
                  {JSON.parse(process.env.REACT_APP_UPI_IDS).map((e) => {
                    return <option hidden={e.upi_id==upiID} value={e.upi_id}>{e.name}</option>;
                  })}
                </Select>
                <Text
                  mt={"2px"}
                  ml={"5px"}
                  textAlign={"left"}
                  fontSize={"12px"}
                  color={"green"}
                >
                  {upiID}
                </Text>
              </FormControl>

              <FormControl mb={4}>
                <Input isInvalid={true}
                  id="upiAmount"
                  type="number"
                  placeholder="Amount"
                  ref={upiAmount}
                  onKeyDown={(e) => {
                    if (e.key == "e") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.preventDefault();
                    } else {
                      resetqr();
                    }
                  }}
                  size="lg"
                  borderColor="#ddd"
                />
              </FormControl>
            </Box>
            <Box
              mb={6}
              boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
              boxSize="128px"
            >
              {qrCodeImage && (
                <Image src={qrCodeImage} alt="QR Code" boxSize="128px" />
              )}
            </Box>
          </Flex>

          <Button
            colorScheme={qrCodeImage!==""?"green":"blue"}
            size="lg"
            onClick={qrCodeImage!==""?handleDownloadQRCode:handleGenerateQRCode}
            width="full"
          >
            {qrCodeImage!==""?"Download QR Code":"Generate"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default PaymentQR;
