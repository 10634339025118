import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
const BcTypePrivateRoute = ({ children }) => {
  const user = useSelector((store) => store.auth.user);
  if (user?.usertype === "bc" || user?.role === "admin") {
    return children;
  } else {
    return <Navigate to={"/"} />;
  }
};

export default BcTypePrivateRoute;
